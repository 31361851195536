html,
body {
  overflow: hidden;
}

.MuiSelect-select.MuiSelect-select{
  padding-left: 5px;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: silver;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.html-wrap {
  text-align: left;
}

.custom-html-style h1 h2 h3 {
  text-align: center !important;
}

.json-to-table td {
  font-family: arial;
  font-size: 10px;
}

.json-to-table table {
  margin-bottom: 5px;
  margin-top: 5px;
}

.transaction-page-container,
.journey-page-container,
.accounts-page-container,
.rules-page-container,
.tax-returns-page-container,
.profile-page-container,
.payment-page-container,
.tax-return-page-container,
.tax-forms-page-container,
.plan-page-container,
.payments-page-container{
  display: flex;
  position: absolute;
  top: 10px;
  /* top: 90px; */
  bottom: 80px;
  width: 100%;
  min-width: 300px;
}


.transaction-page-content,
.journey-page-content,
.accounts-page-content,
.rules-page-content,
.tax-returns-page-content,
.profile-page-content,
.payment-page-content,
.tax-return-page-content,
.tax-forms-page-content,
.tax-breakdown-page-content,
.plan-page-content,
.payments-page-content{
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 98%;
  height: 100%;
  padding-bottom: 14px;
  border-width: 2px;
  margin-left: 10px;
  margin-right: 10px;
  max-width: 100vw !important;
  
} 

.transaction-page-content .MuiContainer-maxWidthLg{
  max-width: 100vw !important;
}

.tax-forms-page-content-no-scroll{
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 98%;
  height: 100%;
  padding-bottom: 14px;
  border-width: 2px;
  margin-left: 10px;
  margin-right: 10px;
  overflow: hidden;
  
}


.profile-page-container .MuiCardContent-root, .profile-page-container .MuiCardContent-root:last-child {
  padding: 0;
}

.profile-page-container .MuiCheckbox-colorSecondary, .profile-page-container .MuiCheckbox-colorSecondary.Mui-checked {
  color: #00338d
}

.csv-import-container {
  display: inline-block;
  margin-top: 25px;
}

.csv-import-container .MuiPaper-rounded {
  padding: 15px 0 15px 0;
}

.csv-import-row {
  display: flex;
  position: relative;
  width: 650px;
  padding: 10px 0 10px 15px;
  text-align: left;
}

.csv-import-step {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  font-style: italic;
}

.csv-import-icon {
  position: absolute;
  right: 10px;
}

.csv-import-icon-left {
  position: absolute;
  right: 30px;
}

.csv-import-icon-right {
  position: absolute;
  right: 0px;
}

.transactions-page-modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.transactions-page-modal-content {
  background-color: #ffffff;
  border: 2px solid #14a7cc;
  width: 95%;
  padding: 20px;
}

.pointer {
  cursor: pointer;
}

.form-save .alert-wrapper .alert-head{
  text-align: center;
 
}

.payment-popup {
  width: 100%;
}
.payment-popup .alert-wrapper  {
  min-width: 600px;
}
.accounts-popup .alert-wrapper {
  min-width: 400px;
  width: fit-content;
  overflow-y: scroll;
}

.rules-action-sheet .action-sheet-container {
  justify-content: center;
  border-radius: 5px;
}
.rules-action-sheet .action-sheet-container .action-sheet-group{
  border-radius: 5px 5px 0px 0px;
}
.rules-action-sheet .action-sheet-container .action-sheet-group-cancel{
  border-radius: 0px 0px 5px 5px;
}


.connect-account-picture {
  width: 170px;
  max-height: 100%;
  max-width: 305px;
  height: 200px;
  display: block;
  margin: auto;
  padding-bottom: 5px;
  background: url("./images/connect-account.png") center no-repeat;
  background-size: contain;
}

.no-transactions-picture {
  width: 300px;
  height: 250px;
  max-height: 100%;
  max-width: 40%;
  display: block;
  margin: auto;
  background: url("./images/no-transactions.png") center no-repeat;
  background-size: contain;
}

.header-no-margin {
  margin: 0;
}

i {
  margin-left: 10px;
  border: solid #00338d;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.expanded {
  transform: rotate(-135deg);
  transition: .1s linear;
  display: inline-block;
  margin-bottom: -2px;
  margin-right: 2px;
}

.collapsed {
  transform: rotate(45deg);
  transition: .1s linear;
  display: inline-block;
  margin-bottom: 2px;
  margin-right: 2px;
}


.my-transaction-popover-wide .popover-content {
  width: 800px;
  height: 675px;
  /* min-height: fit-content;
  min-width: fit-content; */
}
.my-payment-popover-wide .popover-content {
  width: 600px;
  max-height: 90vh;
  /* min-height: fit-content;
  min-width: fit-content; */
}

.my-transaction-popover-wide-short .modal-wrapper {
  height: fit-content;
  width: 800px;
  max-height: fit-content;

}

.my-transaction-popover-wide-short ion-item {
  --padding-start: 0px;
  --padding-end: 0px;
  --inner-padding-start: 0px;
  --inner-padding-end: 0px;
  position: relative;
  width: 95%;
}

/* .my-transaction-popover-wide-short .testing_class{
  overflow-y: "auto"
} */
/* .my-transaction-popover-wide-short .popover-content #claim_content{
  overflow: auto;

} */

.my-transaction-popover .popover-content {
  width: 400px;
  height: fit-content;
  min-width: fit-content;
}

/* .my-ion-trans-popover .popover-viewport.sc-ion-popover-md {
    height: 100%;
} */

.my-ion-trans-popover .modal-wrapper {
  height: fit-content;
  max-height: fit-content;
  width: 800px;
}


ion-popover {
    z-index: 1250 !important;
}

.my-ion-trans-popover .drop-file-container .MuiButton-root:hover{
    background-color: transparent;
}



.my-ion-trans-popover ion-item {
    --padding-start: 0px;
    --padding-end: 0px;
    --inner-padding-start: 0px;
    --inner-padding-end: 0px;
    position: relative;
    width: 95%;
}



.my-ion-trans-popover #amount_claim ion-item{
  width: 100%;
}
.my-ion-trans-popover  #percent_claim ion-item{
  width: 100%;
}

#popover-100 ion-item {
    --padding-start: 0px;
    --padding-end: 0px;
    --inner-padding-start: 0px;
    --inner-padding-end: 0px;
    position: relative;
    width: 100%;
}

.bank-popover {
  --width: fit-content;

}
.bank-popover .popover-content{
  width: 360px;
  overflow-y: hidden;
}
.bank-popover-long {
  --width: fit-content;

}
.bank-popover-long .popover-content{
  width: 360px;
  overflow-y: auto;
}


#add-description ion-label {
  margin-bottom: 0px !important;
}
#add-amount ion-label {
  margin-bottom: 0px !important;
}

.item-has-value ion-label {
    margin-bottom: 10px !important;
}

.grey-input-no-padd {
  --padding-start: 10px;
  
 }
 
 .custom-tags-input ion-label {
  margin-bottom: 0px !important;
}

.recurring-pay-modal .modal-wrapper {
  --height: fit-content;
}

.post-code .native-input {
  padding-top: 3px !important;
}


.my-ion-trans-popover .custom-filter {
    width: 100%;
    padding-right: 50px;
    background-color: #F4F6F6;
    height: 38px;
}
.my-ion-trans-popover .custom-filter-edit {
    width: 100%;
    border: 1px solid #CECECE;
    padding-right: 50px;
    background-color: #F4F6F6;
    height: 38px;
}

.my-ion-trans-popover .custom-filter .MuiInput-root, .my-ion-trans-popover .custom-filter-edit .MuiInput-root{
    height: 100%
}

.my-ion-trans-popover .custom-tags {
    --background: #F4F6F6;
}


.my-ion-trans-popover .MuiInput-underline:after,
.my-ion-trans-popover .MuiInput-underline:before,
.my-ion-trans-popover .MuiInput-underline:hover:not(.Mui-disabled):before,
.custom-filter-payments .MuiInput-underline:after,
.custom-filter-payments .MuiInput-underline:before,
.custom-filter-payments .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none;
}

.my-ion-trans-popover .MuiSvgIcon-root:hover {
    opacity: 1 !important;
}

.my-ion-trans-popover .react-tag-input {
    border: 1px solid #CECECE;
    border-radius: 0;
    background-color: #F4F6F6;
}



.custom-filter-payments {
    width: 100%;
}


.custom-filter-payments .MuiSelect-select.MuiSelect-select {
    padding-left: 0;
}

.custom-filter-payments.right-align .MuiInputBase-input{
    text-align: right;
}

.custom-filter-payments-fake-select .MuiInputBase-input{ 
  cursor: pointer;
}


.plan-card-content-selected {
    border: 2px solid #00338d;
    box-shadow: grey 2px 2px 3px;
    border-radius: 3px
}

/*ag-grid related styles*/

.hide-grid {
  display: none;
}

.filters-container {
  width: 100%;
  flex-direction: row;
  flex-flow: row wrap;
  padding-left: 20px;
  padding-right: 0;
  padding-bottom: 5px;
  margin-bottom: 2px;
}

.filters-container.MuiToolbar-gutters {
  padding-left: 0;
  padding-right: 0;
}

.grid-container {
  height: 100%;
  width: 100%;
  /* max-width: 1300px; */
  flex: 1;
  overflow: hidden;
}
.grid-container-small {
  height: 90%;
  width: 100%;
  /* max-width: 1300px; */
  flex: 1;
  overflow: hidden;
}

.mtd-grid-container {
  margin-top: 30px;
}

.grid-container.import-page {
  height: calc(100% - 90px);
}

.ag-theme-alpine .ag-cell {
  line-height: 30px !important;
  font-size: 12px;
}

.ag-theme-alpine.show-grid .ag-cell-inline-editing {
    height: 30px;
    border: none;
    background: transparent;
}


.ag-theme-alpine.show-grid .ag-cell-inline-editing .ag-cell-edit-wrapper {
    position: absolute;
    top: 0;
    left: 0;
}

 
 .claimed-row {
   border-color: silver !important;
 }
 
 .ag-theme-alpine .ag-ltr .ag-header-select-all, .ag-selection-checkbox {
   position: absolute;
   width: 100%;
   left: 0;
   margin: 0;
   display: flex;
 }
 
 .ag-theme-alpine .ag-checkbox .ag-input-wrapper, .ag-selection-checkbox .ag-input-field {
   margin: auto;
 }
 
 .ag-theme-alpine .ag-header-cell {
   display: flex;
 }
 
 .ag-header-cell .ag-floating-filter-body {
   margin-right: 0;
 }
 
 .ag-floating-filter-button {
   margin-left: 0 !important;
 }
 
 .ag-icon-filter, .ag-icon-asc, .ag-icon-desc, .ag-icon-small-down {
   background: transparent !important;
 }
 
 .ag-theme-alpine .ag-ltr .ag-header-cell:not(.ag-right-aligned-header) .ag-header-label-icon .ag-icon-filter {
   display: none;
 }
 
 .ag-theme-alpine .ag-select .ag-picker-field-icon {
   margin-right: 10px;
 }
 
 .ag-theme-alpine .ag-select:not(.ag-cell-editor) {
   width: 100%;
 }
 
 .ag-filter-select {
   margin: 0 0 5px 0 !important;
 }
 
 .ag-theme-alpine .ag-select .ag-picker-field-wrapper {
   height: 24px;
 }
 
 .ag-theme-alpine .ag-select .ag-picker-field-display {
   text-align: left;
 }
 
 .ag-theme-alpine .ag-picker-field-wrapper {
   border-radius: 3px !important;
 }
 
 .ag-theme-alpine .ag-cell.cell-style, .cell-style {
   text-align: left;
   padding-left: 10px;
   padding-right: 10px;
 }
 
 .ag-theme-alpine .ag-header-cell, .ag-theme-alpine .ag-header-group-cell {
   padding-left: 10px !important;
   padding-right: 10px !important;
 }
 .attachment{
  background: url("./images/attach_file-24px.svg") center no-repeat;
  background-size: contain;
  border: none;
  height: 24px;
  width: 20px;
  margin: auto;
  /* cursor:pointer; */
 }
 .attachmentHeader{
  background: url("./images/attach_file-24px.svg") center no-repeat;
  background-size: "cover";
  border: none;
  height: 100%;
  width: 100%;
  margin:auto;
 }
 .note{
  background: url("./images/receipt-24px.svg") center no-repeat;
  background-size: cover;
  border: none;
  height: 24px;
  width: 20px;
  margin: auto;
  /* cursor:pointer; */
 }

 .lightbulb{
   background: url("./images/lightbulb-24px.svg") center no-repeat ;
   background-size: cover;
   border: none;
   height: 24px;
   width: 20px;
   margin: auto;
   cursor:pointer;
 }
 .chevronUp{
   background: url("./images/chevronUp.svg") center no-repeat ;
   background-size: cover;
   border: none;
   height: 24px;
   width: 20px;
   margin-top: 25px;
   cursor:pointer;
 }
 .chevronDown{
   background: url("./images/chevronDown.svg") center no-repeat ;
   background-size: cover;
   border: none;
   height: 24px;
   width: 20px;
   margin-top: 25px;
   cursor:pointer;
 }

 .paperclip {
   background: url("./images/attach_file-24px.svg") center no-repeat ;
   background-size: auto;
   border: none;
   height: 40px;
   width: 20px;
   margin: 10px auto 10px 10px;
   padding: auto;
 }

 .paperclip:hover{
  background: url("./images/attach_file-24px-black.svg") center no-repeat ;

 }

 .paperclip:active{
  background: url("./images/attach_file-24px.svg") center no-repeat ;
 }

 /* .attachment-added{
  --background: url("./images/attachment_success.png") center no-repeat;
  --height: 150px;
  --width: 600px;
  --background-size: cover;
  border: none;
 } */

 
 .similar{
  background: url("./images/batch_prediction-24px.svg") center no-repeat ;
  background-size: cover;
  border: none;
  height: 24px;
  width: 20px;
  margin: auto;
  cursor:pointer;
 }
 .similar-big{
  background: url("./images/batch_prediction-24px.svg") center no-repeat ;
  background-size: contain;
  border: none;
  height: 100%;
  width: 100%;
  margin: auto;
  cursor:pointer;
 }

 .similarTrans {
  min-width: 150px;
  height: 35px;
  padding-top: 5px;
  border: 1px solid #00338d;
  border-radius: 2px;
  color: #00338d;
  background-color: white;
  text-transform: none;
  margin-left: 10px;
  font-size: 14px;
  cursor:pointer;
  margin-top: 14px;
  
  margin-right: 0px;
}


.similarTrans:hover{
  color: white;
  background-color: #00338d;
  font-weight: bold;
}

.clearFilters {
  height: 35px;
  padding-top: 5px;
  min-width: 100px;
  border: 1px solid #00338d;
  border-radius: 2px;
  color: #00338d;
  background-color: white;
  text-transform: none;
  font-size: 14px;
  cursor:pointer;
  margin-top: 14px;
  /* margin-left: auto;  */
  margin-right: 0px;
}

.clearFilters:hover {
  
  color: #a00338;
  font-weight: bold;
  border: 1px solid  #a00338;
}
.click-span{
  text-align: center;
}

 .message{
  background: url("./images/help_outline-24px.svg") center no-repeat ;
  background-size: cover;
  border: none;
  height: 24px;
  width: 20px;
  margin: auto;
  cursor:pointer;
 }

 .delete{
  background: url("./images/delete_black_24dp.svg") center no-repeat ;
  background-size: cover;
  border: none;
  height: 24px;
  width: 20px;
  cursor:pointer;

 }

 .delete:hover{
  background: url("./images/delete_red_24dp.svg") center no-repeat ; 
 }

 .restore{
  background: url("./images/cached_black_24dp.svg") center no-repeat ;
  background-size: cover;
  border: none;
  height: 24px;
  width: 20px;
  cursor:pointer;
 }

 .restore:hover{
  background: url("./images/cached_green_24dp.svg") center no-repeat ;
 }

 .claimed{
  background: url("./images/receipt-24px.svg") center no-repeat ;
  background-size: cover;
  border: none;
  height: 24px;
  width: 20px;
  margin: auto;
  cursor:pointer;
 }
 /* .check_circle{
  background: url("./images/check_circle_outline-24px.svg") center no-repeat ;
  background-size: contain;
  border: none;
  height: 28px;
  min-width: 40px;
  margin: 10px;
 }
 .info_circle{
  background: url("./images/info-24px.svg") center no-repeat ;
  background-size: contain;
  border: none;
  height: 28px;
  min-width: 40px;
  margin: 10px;
 } */


 
 .cell-style-personal {
   color: #BABABA;
   background-color: #E7E7E7;
   font-weight: bold;
   text-align: center !important;
 }
 
 .cell-style-debit {
   color: #E7E7E7;
   background-color: #7213EA;
   font-weight: bold;
   text-align: center !important;
 }
 
 .cell-style-other {
   color: #00338d;
   background-color: #C7FF00;
   font-weight: bold;
   text-align: center !important;
 }
 
 .ag-ltr .ag-header-cell-resize {
   right: 0 !important;
 }
 
 .ag-theme-alpine .ag-icon-asc:before, .ag-theme-alpine .ag-icon-desc:before {
   position: absolute;
   bottom: 15px;
 }

.ag-icon-filter,
.ag-icon-asc,
.ag-icon-desc,
.ag-icon-small-down {
  background: transparent !important;
}

.ag-theme-alpine
  .ag-ltr
  .ag-header-cell:not(.ag-right-aligned-header)
  .ag-header-label-icon
  .ag-icon-filter {
  display: none;
}

.ag-theme-alpine .ag-select .ag-picker-field-icon {
  margin-right: 10px;
}

.ag-theme-alpine .ag-select:not(.ag-cell-editor) {
  width: 100%;
}

.ag-filter-select {
  margin: 0 0 5px 0 !important;
}

.ag-theme-alpine .ag-select .ag-picker-field-wrapper {
  height: 24px;
}

.ag-theme-alpine .ag-select .ag-picker-field-display {
  text-align: left;
}

.ag-theme-alpine .ag-picker-field-wrapper {
  border-radius: 3px !important;
}

.ag-theme-alpine .ag-cell.cell-style,
.cell-style {
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
}

.ag-theme-alpine .ag-header-cell,
.ag-theme-alpine .ag-header-group-cell {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.cell-style-personal {
  color: #bababa;
  background-color: #e7e7e7;
  font-weight: bold;
  text-align: center !important;
}

.cell-style-debit {
  color: #e7e7e7;
  background-color: #7213EA;
  font-weight: bold;
  text-align: center !important;
}

.cell-style-other {
  color: #00338d;
  background-color: #c7ff00;
  font-weight: bold;
  text-align: center !important;
}

.ag-ltr .ag-header-cell-resize {
  right: 0 !important;
}

.ag-theme-alpine .ag-icon-asc:before,
.ag-theme-alpine .ag-icon-desc:before {
  position: absolute;
  bottom: 15px;
}

.menu-top-container {
  height: 150px;
}

.menu-icon-container {
  height: 40px;
  position: relative;
}

.arrow-left, 
.arrow-right {
  color: #00338d;
  height: 40px;
  width: 30px;
  position: relative;
  top: 40%;
}



.menu-arrow-container {
    height: 30px;
    position: relative;

}

.menu-arrow-container button {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
}


.untied-logo {
  background: url("./images/kpmg-logo-1.jpeg") bottom no-repeat;
  background-size: contain;
  height: 100%;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.untied-tax-berry {
  background: url("./images/character.png") center no-repeat;
  background-size: contain;
  height: 100%;
  width: 30px;
  margin: auto;
}
.untied-tax-berry-green {
  background: url("./images/character_green.png") center no-repeat;
  background-size: contain;
  height: 100%;
  width: 30px;
  margin: auto;
}


.logout-item {
    position: absolute;
    bottom: 60px;
    width: 100%;
}

.footer-item{
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.menu-item-default.MuiListItem-gutters, .menu-item-current.MuiListItem-gutters {
    padding: 0;
    height: 26px;
    font-size: 13px;
}

.menu-item-default .MuiListItemIcon-root {
    color: #1e49e2;
    height: 100%;
}

.menu-item-default .MuiListItemIcon-root svg{
    fill: #1e49e2;
    margin: auto;
}


.menu-item-default-upgrade.MuiListItem-gutters, .menu-item-default-upgrade.MuiListItem-button:hover {
    /* background-color: #00338d; */
    font-weight: bold;
    color: #1e49e2;
    padding: 0;
    height: 26px;
    font-size: 13px;
    
   
}

.menu-item-default-upgrade .MuiListItemIcon-root {
    /* background-color: #00338d; */
    font-weight: bold;
    color: #1e49e2;
    height: 100%;
}

.menu-item-default-upgrade .MuiListItemIcon-root svg{
    fill: #1e49e2;
    margin: auto;
}

.menu-item-current .MuiListItemIcon-root {
    background: #1e49e2;
    color: #ffffff;
    height: 100%;
}

.menu-item-current .MuiListItemIcon-root svg{
    background: #1e49e2;
    fill: #ffffff;
    margin: auto;
}

.menu-item-current .item-label {
    width: 100%;
    color: #ffffff;
    background: #1e49e2;
    height: 100%;
    line-height: 26px;
}

.mobile-overlay-hidden {
    width: 0;
    transition: width .4s;
    background-color: #fff;
    height: 0px;
    position: absolute;
    z-index: 1;
}

.mobile-overlay {
    width: 240px;
    height: 40px;
    position: absolute;
    background-color: #fff;
    z-index: 1;
    transition: width .4s;
}

.logged-in-container {
    /* background: #00338d; */
    /* background: #D8FFFF; */
    /* opacity: .7; */
    /* color: white;
     */
    /* font-weight: bold; */
    /* margin: 10px; */
    margin-left: 20px;
    white-space: nowrap;
    height: 30px;
    /* font-style: italic; */
    text-align: left;
}

.payment-form-confirmation-container {
    height: 100%;
    overflow: hidden;
    position: relative;
}


.tax-forms-cell {
  width: 250px;
}

.tax-forms-buttons-container {
  position: relative;
  height: 100px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 26px 0;
}


.tax-forms-menu-container {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  
}

.tax-forms-menu-content {
  width: 33.33%;
}

.tax-forms-menu-content {
  width: 100%;
}

@media only screen and (min-width: 568px) {
  .tax-forms-menu-content {
      width: 50%;
  }
}

@media only screen and (min-width: 1024px) {
  .tax-forms-menu-content {
      width: 33.33%;
  }
}

/* .video-player-floating {
  min-height: 50vh;
  min-width: 50vw;
  width: 1000px;
  height: 500px;

} */

.red-link {
  color: #d10049;
}

.tax-forms-menu-card {
  height: 200px;
  min-height: 200px;
  cursor: pointer;
}

.tax-forms-card-title {
  color: #00338d;
  text-align: left;
}

.hub-page-content {
  background-color: white;
  box-shadow: 0 0 6px rgba(0,0,0,0.3);
  border-radius: 5px;
  overflow-y: auto;
}

.wide-row{
  width: 100%;
  margin-bottom: 15px;
}

.no-scroll {
  overflow-y: hidden;
  /* overflow-y: hidden; */
}

.hub-page-header{
  color: #00338d;
  font-weight: bold;
  font-size: 24px;
  margin: 20px;
  margin-bottom: 10px;
  text-align: left;
}
.hub-checkbox{
  min-width: 10px;
  font-size: 32px;
  --border-width: 2px;
  --border-color: #00338d;
  --background-checked: #00338d;
  margin-top: 10px;

}
.hub-checkbox-no-margin{
  min-width: 10px;
  font-size: 32px;
  --border-width: 2px;
  --border-color: #00338d;
  --background-checked: #00338d;
}
.grid-scoll {
  overflow-y: scroll !important;
}

.small-link{
  margin-top: 2px;
  width: 15px;
  height: 15px;
}

.hub-flex-grid{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* width: 100%; */
  justify-content: space-between;
  
}

.hub-row{
  margin: 10px;
  display: flex;
}

.hub-text{
  width: 100%;
  text-align: left;
}
.hub-text-link{
  width: 100%;
  text-align: left;
  color: #d10049;
  text-decoration: underline;
  cursor: pointer;
}

.hub-hints-info {
  background-color: rgba(243, 250, 251, 1);
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 90%;
  height: 95%;
  display: flex;
}

.tax-forms-card-content {
  position: relative;
  height: 110px;
  overflow: auto;
  text-align: left;
}

.tax-forms-more-text-content {
  position: absolute;
  /* bottom: 16px; */
  top: 15px;
  right: 16px;
  cursor: pointer;
  text-decoration: underline;
  color: #00338d;
  z-index: 999;
}


.native-input.sc-ion-input-md::-webkit-input-placeholder {
    text-align: left;
}

.native-input.sc-ion-input-md:-moz-placeholder {
    text-align: left;
}

.native-input.sc-ion-input-md::-moz-placeholder {
    text-align: left;
}

.native-input.sc-ion-input-md:-ms-input-placeholder {
    text-align: left;
}


.tax-forms-menu-card {
    height: 200px;
    min-height: 200px;
    cursor: pointer;
}

.fake-anchor {
  color: rgba(209,0,73,1.0)!important;
  cursor: pointer;
  text-decoration-line: underline;
}

@media screen and (max-width: 1060px) {
  .barBox {
    align-items: center;
    align-content: center;
    width: 30%;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: 15px;
    min-width: 100px;
   
  }
  .barBoxRight{
    align-items: center;
    align-content: center;
    width: 30%;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: 15px;
    min-width: 100px;
  
  }
}

@media screen and (min-width: 1061px) {
  .barBox {
    
    align-items: center;
    align-content: center;
    width: 30%;
    display: flex;
    flex-direction: column;
    margin: auto;
    min-width: 150px;
    float: right;

  }
  .barBoxRight{
    
    align-items: center;
    align-content: center;
    width: 30%;
    display: flex;
    flex-direction: column;
    margin: auto;
    min-width: 150px;
    float: right;
 
  }  
}

.tax-forms-more-text-content {
    position: absolute;
    /* bottom: 16px; */
    top: 15px;
    right: 16px;
    cursor: pointer;
    text-decoration: underline;
    color: #00338d;
    z-index: 999;
}

