ion-card-content h1 {
  margin-bottom: 20px !important;
}

ion-card-content p {
  margin-bottom: 10px !important;
}

ion-toolbar {
  --background: #00338d;
}

ion-content ion-toolbar {
  --background: white;
}

ion-item {
  --border-color: #00338d;
}

ion-item-option {
  --background: #941e3b;
}

.ion-margin{
  margin: 20px 0px;
}

.ion-addtx-margin{
  margin: 5px 0px;
}

.my-ion-popover {
  --width: 50%;
}

.my-ion-submit-type-popover {
  --width: 500px;
}

.my-ion-trans-popover {
  --width: 500px;
}

.my-ion-trans-popover .grey-input {
  --background: #F4F6F6;

}

.my-ion-journey-popover {
  --width: 500px;
}
.my-ion-journey-popover ion-item {
  width: 100%;
  --padding-start: 0px;
}

.my-ion-journey-popover .grey-input {
  --background: #F4F6F6;

}

.my-ion-journey-popover-wide {
  --width: 700px;
}
.my-ion-journey-popover-wide ion-item {
  width: 100%;
  --padding-start: 0px;
}

.my-ion-journey-popover-wide .grey-input {
  --background: #F4F6F6;

}

.my-transaction-popover-wide-short .grey-input {
  --background: #F4F6F6;

}
.my-ion-trans-popover .white-input {
  --background: white;
}
.my-ion-trans-popover .white-input .custom-ion-native{
  --background: white;
}

.my-ion-trans-popover .custom-ion-native {
  --background: #F4F6F6;
}

.milesBox {
  min-width: 150px;
  width:max-content;
  padding-left: 5px;
  padding-right: 2px;

}

.mainMilesBox{
  min-width: 300px;
  width:max-content;
  display: flex;
  text-align: left;
  height: 55px;
  align-content: center;
  margin-right: 25px;
 
}

.my-ion-map-popover {
  --width: "6000px";
}

.receipt-image{
  height: 95%;
  width: 95%;
}

/* .popover-content.sc-ion-popover-md {
  top: 10% !important;
} */

.ion-button-money {
  --background: #00338d;
  --box-shadow: "none"
}

.no-outline {
  outline: none !important;
} 

.no-outline:focus {
  outline: none !important;
} 

.no-outline .modal-wrapper{
  width: 50vw !important;
  min-width: 450px;
  height: fit-content;
  border-radius: 5px;
}

.ion-button-money-initial, .ion-button-money-discard {
  --background: #ffffff;
  border: 1px solid #00338d;
  color: #00338d;
  border-radius: 4px;
  
}

.ion-button-money-discard:hover {
  --background: #ffffff;
  border: 1px solid #a00338;
  color: #a00338;
  border-radius: 4px;
}

.no-padding .MuiSelect-select.MuiSelect-select {
  padding: 0;
}

.ion-button-inverse {
  --border-style: solid;
  --border-width: 2px;
  --border-color: #00338d;
  --color: #00338d;
  --background: white;
  --background-hover: grey;
  --box-shadow: "none"
}

.ion-button-sub {
  --background: #c7ff00;
}

.stay-blue-link {
  color: blue;
}

.stay-blue-link:hover {
  color: purple;
}

.money-input {
  /* --border-color: transparent!important;
  --highlight-height: 0;
  border: 1px solid #dedede;
  border-width: 0 0 1px 0;
  border-radius: 4px; */
  text-align: right;
  padding: 0;
}

.money-input ion-input {
  --padding-bottom: 0;
  --padding-top: 0;
}

.money-select ion-select {
  --padding-start: 0;
  width: 100%;
  max-width: 100%;
}

.selectable {
  -webkit-user-select: text;
  -khtml-user-select: auto;
  -moz-user-select: all;
  -ms-user-select: auto;
  -o-user-select: auto;
  user-select: auto;
}

.helpText {
  color: #000000 !important;
}


.gridButton {
  background-color: #00338d;
  color: white;
  border-color: #00338d;
  border-style: solid;
  border-radius: 3px;
  cursor: pointer;
}

.editJourneyButton {
  background-color: transparent;
  border: none;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  cursor: pointer;
}

.editJourneyButton:focus {
  outline: none;
}

.edit-journey-icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 20px;
  height: 20px;
}

.input-container {
  --padding-start: 0;
  --inner-padding-end: 0;
  font-size: 14px;
  --background-hover: transparent;
  --highlight-color-focused: #00338d;
  --highlight-height: 0;
  --ion-color-primary: #00338d;
}

.input-container .MuiInput-underline:before,
.input-container .MuiInput-underline:after,
.input-container .MuiInput-underline:hover:not(.Mui-disabled):before {
  border: none;
}

ion-icon, a ion-icon {
  position: absolute;
  bottom: 3px;
}
ion-label {
  white-space: normal !important;
  padding-right: 30px;
}
a ion-icon {
  bottom: 14px;
  margin-left: 10px;
}

ion-select,
.native-input {
  --padding-bottom: 0;
}

.in-item {
  margin: 0;
}

ion-checkbox {
  --border-width: 2px;
  --border-color: #00338d;
  --background-checked: #00338d;
  --border-color-checked: #00338d;
}

ion-select {
  --padding-start: 0;
  max-width: 100%;
  width: 100%;
}

.tag-selector-modal {
  --width:420px;
  --height:590px;
  --backdrop-opacity: 0.3;
  --border-radius: 5px;
}
.income-expense-selector-modal {
  --width:420px;
  --height:fit-content;
  --backdrop-opacity: 0.3;
  --border-radius: 5px;
  
}
.tag-confirm-modal {
  --width:500px;
  --height:fit-content;
  --backdrop-opacity: 0.3;
  --border-radius: 5px;

}

.tag-confirm-modal .modal-wrapper {
  position: absolute;
  top: 110px;
  left: 230px;
}

.toolbar-tag-selector-modal .modal-wrapper {
  position: absolute;
  top: 110px;
  left: 230px;
}
.video-modal {
  --width:600px;
  --height:fit-content;
  --backdrop-opacity: 0.3;
  --border-radius: 5px;
}
.no-padding-select .item-inner{
  --padding-inline-end: 0;
  --padding-end:0;
  --inner-padding-end: 0;
}

.set-password-modal {
  --width:500px;
  --height:300px;
}


.my-caret-dropdown-icon {
  color:rgb(165,165,165);
  position:absolute;
  right: 5px;
  top: 50%;
  margin-top: -2px;
  width: 0px;
  height: 0px;
  border-top: 5px solid;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  pointer-events: none;
}


.my-tax-selector-input-padded input {
  cursor: pointer!important;
  --padding-start: 9px;
  padding-top: 0;
}
.my-tax-selector-input-padded .native-input.sc-ion-input-md {
  padding-top: 5px;
  padding-bottom:0 px;
}

.left-indent .native-input.sc-ion-input-md{
  padding-left: 2px;
}

.my-tax-selector-input input {
  cursor: pointer!important;
  --padding-start: 0px;
  --padding-top: 0;
}
.my-tax-selector-input input {
  cursor: pointer!important;
  --padding-start: 0px;
  --padding-top: 0;
}

.my-tax-selector-input-wrapper {
  position: absolute !important;
  bottom: 0;
  --padding-top: 0;
}

.my-tax-selector-input-wrapper:hover {
  --ion-background-color: rgb(245,245,246) !important;
}

.item-label-floating .sc-ion-input-md-h {
  --padding-top: 0;
}

.native-input.sc-ion-input-md {
  padding-top: 13px;
  padding-bottom: 5px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  display: none;
  margin: 0;
}

.tax-tag-item {
  flex-direction: column;
  width:400px;
  border-bottom-width:1px;
  border-bottom-color:#999;
  border-bottom-style:solid;
  font-weight:600;
  color: #00338d;
}

.income-expense-tag-item {
  flex-direction: column;
  width:400px;
  /* border-bottom-width:1px;
  border-bottom-color:#999;
  border-bottom-style:solid; */
  /* font-weight:600; */
  /* color: #00338d; */
}

.reset-trans {
  border: 2px solid #00338d;
  border-radius: 5px;
  color: "grey";
  background-color: "white";
  text-transform: "none";
  cursor: pointer;
  height: 35px;
  margin-top: 14px;

}

.reset-trans:hover{
  color: #a00338;
  border: 2px solid #a00338;
}

.similar-years{
  padding: 16px 0px 16px 16px;
  color: grey; 
}
.similar-years:hover{
  background-color: rgb(236, 236, 236);
  color: black;
  cursor: pointer;
}


.tax-tag-item-description {
  font-size:11px;
  white-space:normal;
  font-weight:normal;
  color:black;
}

.tax-tag-modal-item {
  padding-top:5px;
  padding-bottom:5px;
  padding-left:10px;
  padding-right:10px;
  cursor: pointer;
}

.tax-tag-modal-item:hover {
  background-color:rgb(245,245,246);
}
.income-expense-modal-item {
  padding-top:10px;
  padding-bottom:10px;
  padding-left:10px;
  padding-right:10px;
  cursor: pointer;
}

.income-expense-modal-item:hover {
  background-color:rgb(245,245,246);
}

.tax-forms-input .MuiInputLabel-formControl{
  transform: none;
  color: #000000;
  text-align: left;
  font-size: 14px;
  padding-top: 10px;
}

.tax-forms-input .MuiInput-root input {
  text-align: right;
  background-color: #ECF0F1;
  border: 1px solid #AAB7B8;
  border-radius: 5px;
  font-size: 14px;
  padding-right: 5px;
}

.tax-forms-input-textarea-multiline .MuiInput-root textarea {
  text-align: left;
  background-color: #ECF0F1;
  border: 1px solid #AAB7B8;
  border-radius: 5px;
  font-size: 14px;
  padding-right: 5px;
  height: 60px !important;
}

.tax-forms-input-textarea-multiline .Mui-focused {
  color: #000000 !important;
}

.tax-forms-input .MuiInput-root {
  height: 100%;
  width: 100%;
  margin-top: 0;
  padding-left: 10px;
}

.tax-forms-input-textarea-multiline .MuiInput-root {
  padding-left: 0;
}

.custom-filter-input {
  width: 100%;
  height: 70px;
  color: #000000
}

.custom-filter-input .MuiInput-underline:before,
.custom-filter-input .MuiInput-underline:hover:not(.Mui-disabled):before,
.custom-filter-input .MuiInput-underline:after,
.custom-filter-input .WithStyles\(ForwardRef\(TextField\)\)-root-13 .MuiInput-underline:before,
.tax-forms-input-textarea .MuiInput-underline:before,
.tax-forms-input-textarea .MuiInput-underline:after,
.tax-forms-input-textarea .MuiInput-underline:hover:not(.Mui-disabled):before,
.tax-forms-input-textarea-multiline .MuiInput-underline:before,
.tax-forms-input-textarea-multiline .MuiInput-underline:hover:not(.Mui-disabled):before,
.tax-forms-input-textarea-multiline .MuiInput-underline:after{
  border: none;
}


.tax-forms-input-textarea .MuiInput-root input {
  text-align: left;
  margin-bottom: -5px;
  padding-left: 5px;
}


.tax-forms-input-label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  width: 95%;
}

.tax-forms-input .custom-filter-input .MuiFormControl-root {
  width: 100%;
  height: 70px !important;
  margin-top: 0px;
}

.tax-forms-input ion-item {
  width: 20%;
  --inner-padding-end: 0px;
  --border-style: none;
}

.tax-forms-input .MuiSelect-select.MuiSelect-select {
  font-size: 12px;
  border: 1px solid #AAB7B8;
  background-color: #ECF0F1;
  border-radius: 5px;
  padding-left: 5px;
}

.filter-container-tag-confirm {
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  padding: 0 ;
  text-align: left;
  padding-bottom: 4px;
}

.filter-container-tag-confirm .MuiInput-underline:before,
.filter-container-tag-confirm .MuiInput-underline:hover:not(.Mui-disabled):before,
.filter-container-tag-confirm .MuiInput-underline:after {
  border: none;
  padding-left: 0;
  padding-bottom: 4px;
}

.filter-container-tag-confirm .MuiSelect-select.MuiSelect-select{
  padding-left: 0;
  padding-bottom: 4px;
}



.filter-container-tag {
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  padding: 0 5px 0 5px;
  text-align: left;
}

.filter-container-tag .MuiButton-text {
  padding: 0;
  max-width: 150px;
  color: white;
}

.filter-container-tag .MuiInputBase-input {
  text-align: left;
  width: 230px;
  color: white;
}

.filter-container-tag .MuiInput-underline:before,
.filter-container-tag .MuiInput-underline:hover:not(.Mui-disabled):before,
.filter-container-tag .MuiInput-underline:after {
  border: none;
  color: white;
}

.filter-container {
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  padding: 0 5px 0 5px;
  text-align: center;
}

.filter-container .MuiButton-text {
  padding: 0;
  max-width: 50px;
}

.filter-container .MuiInputBase-input {
  text-align: center;
  width: 80px;
}

.filter-container .MuiInput-underline:before,
.filter-container .MuiInput-underline:hover:not(.Mui-disabled):before,
.filter-container .MuiInput-underline:after {
  border: none;
}

.reset-form-button {
  width: 100%;
  --color: #a00338;
  --background: #ffffff;
  --border-color: #a00338;
  --border-style: solid;
  --border-width: 1px;
  --box-shadow: 0;
}

.reset-form-button:active {
  --box-shadow: 0;
  margin-top: 5px;
}

.alert-radio-group::-webkit-scrollbar,
.alert-checkbox-group::-webkit-scrollbar {
  width: 1em;
  display: block !important;
}

.alert-radio-group::-webkit-scrollbar-track,
.alert-checkbox-group::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.alert-radio-group::-webkit-scrollbar-thumb,
.alert-checkbox-group::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.taxFormLabelExample {
  font-style: italic;
  font-weight: 300;
}

.taxFormsHeading {
  color: #00338d;
  margin-bottom: 0px;
}

.taxFormsSubHeading {
  margin-bottom:0px;
}

.taxFormLink {
  color: #d10049;
}